.avatar-xs {
    background-size: 25px;
}

.avatar-md {
    background-size: 40px;
}

.avatar-lg {
    background-size: 52px;
}

.App-brand {
    padding-top: 21px;
    padding-bottom: 20px;
    height: auto;
    float: none;
    display: block;
}

.nav {
    float: left;
}

.nav a {
    outline-offset: -5px;
}

.nav>li {
    display: inline-block;
}

.nav>li>a {
    padding-top: 24px;
    padding-bottom: 24px;
    color: rgba(255, 255, 255, 0.59);
    font-size: 13px;
}

.nav>li>a:hover,
.nav>li>a:focus {
    color: #fff;
    background: transparent;
}

.nav>li>a.dropdown-toggle:focus,
.nav>li>a.dropdown-toggle:active {
    outline: 5px auto -webkit-focus-ring-color;
    background-color: transparent;
}

.nav>li.dropdown.open>a.dropdown-toggle {
    background-color: transparent;
}

.nav>li.dropdown>.dropdown-menu {
    top: 63px;
}

.AppBar .search-form {
    display: flex;
    float: left;
    padding-top: 9px;
    padding-bottom: 9px;
    min-width: 306px;
    margin-top: 14px;
    margin-right: 15px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.15);
    box-shadow: none;
}

.AppBar .search-form i {
    color: #fff;
    font-size: 20px;
    margin-right: 10px;
}

.nav>li.upgrade-link a {
    color: #fff;
    font-weight: bold;
    letter-spacing: 0.5px;
    font-size: 11px;
    padding-top: 25px;
    padding-bottom: 25px;
}

.nav>li.avatar-dropdown {
    float: right;
}

.nav>li.avatar-dropdown>.dropdown-toggle>.avatar {
    line-height: 14px;
}

.nav>li.avatar-dropdown>a {
    padding-top: 20px;
    padding-bottom: 20px;
}

.nav>li.avatar-dropdown .dropdown-menu a>i {
    color: #b0b0b0;
    font-size: 16px;
    position: relative;
    top: 2px;
    margin-right: 9px;
}

.nav>li.avatar-dropdown .username {
    color: #4C5656;
    font-size: 13px;
    font-weight: 600;
    text-transform: none;
    margin: 0;
}

.nav>li.avatar-dropdown .subtitle {
    margin: 0;
    font-size: 13px;
    letter-spacing: 0.45px;
    text-transform: capitalize;
}

.nav>li.nav-item-icon>a {
    height: 66px;
    padding-top: 11px;
    padding-bottom: 10px;
}

.nav>li.nav-item-icon>a>.red-indicator {
    top: 9px;
}

.AppBar .search-form input {
    font-size: 13px;
    background-color: transparent;
    color: #fff;
}

.App-intro {
    font-size: large;
}

@media screen and (max-width: 600px) {
    .popover-filters {
        width: 100%;
    }
    .popover-filters .date-filters {
        display: block !important;
    }
}

.popover-filters .date-filters {
    display: flex;
    justify-content: space-between;
}

.popover-filters .date-filters>span {
    margin-top: 25px;
    margin: 25px 8px 0px;
}

.dropdown-menu>li>a>i {
    font-size: 22px;
    margin-right: 17px;
    position: relative;
    top: 4px;
}

.search-form-clear-button,
.search-form-clear-button:before {
    height: 20px;
    width: 20px;
}

.text-muted {
    color: #8EA7A7;
}

.text-danger {
    color: #FF4E4A !important;
}

.text-success {
    color: #9dc954 !important;
}

.text-warning {
    color: #f0ad4e !important;
}

.text-primary {
    color: #5BD672 !important;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.m-none {
    margin: 0 !important;
}

.m-l {
    margin-left: 15px !important;
}

.m-r-none {
    margin-right: 0 !important;
}

.m-r-xs {
    margin-right: 5px !important;
}

.m-r-sm {
    margin-right: 10px !important;
}

.m-r {
    margin-right: 15px !important;
}

.m-r-md {
    margin-right: 20px !important;
}

.m-r-lg {
    margin-right: 30px !important;
}

.m-r-xl {
    margin-right: 40px !important;
}

.m-l-xs {
    margin-left: 5px !important;
}

.m-l-sm {
    margin-left: 10px !important;
}

.m-l-lg {
    margin-left: 30px !important;
}

.m-l-none {
    margin-left: 0 !important;
}

.m-t {
    margin-top: 15px !important;
}

.m-t-xs {
    margin-top: 5px !important;
}

.m-t-sm {
    margin-top: 10px !important;
}

.m-t-xl {
    margin-top: 40px !important;
}

.m-t-lg {
    margin-top: 30px !important;
}

.m-t-md {
    margin-top: 20px !important;
}

.m-t-none {
    margin-top: 0 !important;
}

.m-b {
    margin-bottom: 15px;
}

.m-b-xs {
    margin-bottom: 5px !important;
}

.m-b-sm {
    margin-bottom: 10px !important;
}

.m-b-md {
    margin-bottom: 20px !important;
}

.m-b-lg {
    margin-bottom: 30px !important;
}

.m-b-xl {
    margin-bottom: 40px !important;
}

.m-b-none {
    margin-bottom: 0 !important;
}

.p-all-md {
    padding: 20px !important;
}

.p-all-lg {
    padding: 30px !important;
}

.p-all-xl {
    padding: 40px !important;
}

.p-t-lg {
    padding-top: 30px !important;
}

.p-t-md {
    padding-top: 20px !important;
}

.p-b-lg {
    padding-bottom: 30px !important;
}

.p-b-sm {
    padding-bottom: 10px !important;
}

.p-l-sm {
    padding-left: 10px !important;
}

.p-l-md {
    padding-left: 20px !important;
}

.p-r-sm {
    padding-right: 10px !important;
}

.p-none {
    padding: 0 !important;
}

.p-l-none {
    padding-left: 0 !important;
}

.p-r-none {
    padding-right: 0 !important;
}

.p-b-none {
    padding-bottom: 0 !important;
}

/* xs */

@media (max-width: 767px) {
    .m-t-sm-xs {
        margin-top: 10px !important;
    }
    .m-t-md-xs {
        margin-top: 20px !important;
    }
    .m-t-lg-xs {
        margin-top: 30px !important;
    }
    .m-b-sm-xs {
        margin-bottom: 10px !important;
    }
    .m-b-md-xs {
        margin-bottom: 20px !important;
    }
    .m-l-sm-xs {
        margin-left: 10px !important;
    }
    .m-none-xs {
        margin: 0 !important;
    }
    .p-l-none-xs {
        padding-left: 0 !important;
    }
    .p-b-sm-xs {
        padding-bottom: 10px !important;
    }
    .p-b-xs-xs {
        padding-bottom: 5px !important;
    }
    .text-center-xs {
        text-align: center;
    }
    .text-left-xs {
        text-align: left;
    }
    .pull-right-xs {
        float: right !important;
    }
    .pull-left-xs {
        float: left !important;
    }
    .pull-none-xs {
        float: none !important;
    }
    .visible-xs-flex {
        display: flex !important;
    }
}

/* sm */

@media (min-width: 768px) and (max-width: 991px) {
    .p-l-none-sm {
        padding-left: 0 !important;
    }
    .p-r-none-sm {
        padding-right: 0 !important;
    }
    .m-l-none-sm {
        margin-left: 0 !important;
    }
    .m-t-md-sm {
        margin-top: 20px !important;
    }
    .pull-right-sm {
        float: right !important;
    }
    .pull-left-sm {
        float: left !important;
    }
    .p-l-none-sm {
        padding-left: 0 !important;
    }
    .visible-sm-flex {
        display: flex !important;
    }
}

/* md */

@media (min-width: 992px) and (max-width: 1199px) {
    .text-center-md {
        text-align: center;
    }
    .text-left-md {
        text-align: left;
    }
    .pull-left-md {
        float: left;
    }
    .pull-right-md {
        float: right;
    }
    .m-t-c-md {
        margin-top: 51%;
    }
    .p-l-none-md {
        padding-left: 0 !important;
    }
    .visible-md-flex {
        display: flex !important;
    }
}

/* lg */

@media (min-width: 1200px) {
    .text-left-lg {
        text-align: left;
    }
    .pull-left-lg {
        float: left;
    }
    .pull-right-lg {
        float: right;
    }
    .m-t-c-lg {
        margin-top: 46%;
    }
}

.display-block {
    display: block;
}

.display-inline-block {
    display: inline-block;
}

.display-none {
    display: none !important;
}

.vertical-align-middle {
    vertical-align: middle;
}

.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    width: 100%;
    display: block;
}

.flex-flow-wrap {
    flex-flow: wrap;
}

.floating-button {
    position: absolute;
    right: 17px;
    top: -30px;
    float: right;
}

.modal-subheader {
    font-weight: 600;
    border-bottom: 1px solid #abbdbd;
    padding-bottom: 8px;
    margin-bottom: 30px;
}

.table-wrapper {
    border: 1px solid #e6e8ec;
    position: relative;
}

.table-wrapper table {
    border: 0;
}

.table-wrapper table>thead>tr {
    border: 0;
}

.table-wrapper table>tbody>tr {
    border: 0px;
    border-bottom: 1px solid #e6e8ec;
    border-top: 1px solid #e6e8ec;
}

.table-wrapper>.pager {
    border: 0;
}

.table-thin>thead>tr>th,
.table-thin>thead>tr>td {
    padding: 10px 20px;
}

.table-thin>tbody>tr>td {
    padding: 15px 20px;
}

.center-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.breadcrumb a {
    color: #FFFFFF !important;
    opacity: 0.59;
    display: inline;
    margin-left: 3px;
}

.breadcrumb {
    margin-top: 27px !important;
    margin-bottom: 0px !important;
}

.breadcrumb .link-back {
    display: flex;
    justify-content: center;
    align-items: center;
}

.breadcrumb i {
    font-size: 13px;
    display: inline;
    opacity: 0.59;
}

.muted-link {
    color: inherit;
    padding-bottom: 5px;
    border-bottom: 1px solid #4c5656;
}

@media (min-width: 768px) {
    .container {
        width: 768px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}

@media (max-width: 768px) {
    .navbar-header {
        display: flex;
        justify-content: center;
    }
    .navbar-header .menu-icon {
        display: block !important;
        position: absolute;
        left: 14px;
        top: 20px;
    }
    .dropdown-avatar {
        display: none;
    }
    .nav-off-screen {
        display: block !important;
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        width: 55%;
        visibility: visible;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
    .nav-off-screen~* {
        background-color: #f7f7f7;
        -webkit-transition: -webkit-transform .2s ease-in-out;
        -moz-transition: -moz-transform .2s ease-in-out;
        -o-transition: -o-transform .2s ease-in-out;
        transition: transform .2s ease-in-out;
        -webkit-transition-delay: 0s;
        -moz-transition-delay: 0s;
        -o-transition-delay: 0s;
        transition-delay: 0s;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform: translate3d(55%, 0, 0);
        -moz-transform: translate3d(55%, 0, 0);
        -ms-transform: translate3d(55%, 0, 0);
        -o-transform: translate3d(55%, 0, 0);
        transform: translate3d(55%, 0, 0);
        overflow: hidden;
        position: relative;
        top: 0;
        left: 0;
        height: 100%;
        z-index: 2;
    }
    .AppBar .container>.nav {
        width: 100%;
        display: none;
    }
    .modal .modal-body {
        padding: 20px 35px;
    }
    /*for Panels*/
    .panel .panel-body {
        padding: 20px 35px;
    }
    .widget-content .activity .student-avatar .avatar {
        float: none !important;
    }
}

@media (max-width: 350px) {
    .navbar-header {
        padding-left: 25px;
    }
}

.nav-off-screen {
    display: none;
    background-color: #FFF;
}

.nav-off-screen .nav li {
    display: block;
    text-align: left;
}

.nav-off-screen .nav li a {
    color: #4c5656;
}

.nav-off-screen .nav li a:hover {
    color: #4c5656 !important;
    background-color: #f3f6f6
}

.nav-off-screen .nav li.active a {
    color: #4c5656 !important;
    background-color: #f3f6f6;
    font-weight: 600;
}

.nav-off-screen .nav {
    float: none;
}

.nav-off-screen .menu-item a {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: left;
    align-items: center;
}

.nav-off-screen .menu-item i {
    margin-right: 5px;
}

.nav-off-screen .menu-item:last-of-type {
    margin-bottom: 10px;
}

.nav-off-screen .divider {
    height: 1px;
    margin: 10px 0;
    overflow: hidden;
    background-color: #e6e8ec;
}

.nav-off-screen .content-avatar {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 16px;
    align-items: center;
}

.nav-off-screen .content-avatar .role {
    color: #8ea7a7;
    font-size: 14px;
    margin-top: 6px;
    margin-bottom: 0px;
}

/*
    styles for tables responsives
*/

table {
    width: 100%;
    table-layout: fixed;
}

table .content-without-item {
    background-color: 'transparent';
    border: 0px;
    height: 100%;
}

@media screen and (max-width: 600px) {
    table thead {
        height: 1px;
        width: 0px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
    }
    table tr {
        display: block;
    }
    table td {
        display: block;
        text-align: right;
        padding: 15px 20px !important;
    }
    table td:before {
        content: attr(data-label);
        float: left;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        color: #8ea7a7;
        border: none;
        line-height: 17px;
        vertical-align: middle;
        white-space: nowrap;
    }
    table td:last-child {
        border-bottom: 0;
    }
    table .content-box {
        min-height: 300px;
    }
}

/* TableMesa Theme*/

.AppBar {
    background: linear-gradient(46.44deg, #2571B7 0%, #039BE6 99.9%);
}

.ec-page-header {
    background: linear-gradient(46.43deg, #2571B7 0%, #039BE6 99.9%);
}

#myTabs {
    background: linear-gradient(92.43deg, #2571B7 0%, #039BE6 99.9%);
}

.page-header-nav {
    background-color: transparent;
}

.bg-body {
    background-color: #F5F9FA;
}

.panel-info {
    text-align: left;
    font-size: 14px;
}

.panel-info h4 {
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    color: #4C5656;
}

.panel-info a.info-link {
    font-weight: 600;
}

.panel-info p {
    color: #707B7B;
    line-height: 19px;
}

.ec-checkbox-label {
    display: inline-block !important;
    top: 0 !important;
    margin-left: 0 !important;
}

.appbar {
    -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, .12);
    box-shadow: 0 2px 2px rgba(0, 0, 0, .12);
}

.course-card .card {
    margin-top: 0px;
    flex-basis: 0;
    padding: 0px;
    min-width: 0px;
    display: block;
    background-color: transparent;
    border-radius: 0;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.05), 0 0px 0px rgba(0, 0, 0, 0.04);
    font-size: 16px;
    flex-direction: initial;
}

.ec-page-header h2 {
    color: #fff;
    font-weight: 300;
}

.select-widget {
    margin-top: 0 !important;
    padding: 0 !important;
}

.select-widget .select-trigger {
    font-size: 13px !important;
}

.btn-threedot-dropdown {
    min-width: 0 !important;
    height: 35px;
}

.btn-threedot-dropdown .spinner-sm {
    top: -7px;
    position: relative;
}

/*custom styles*/

@media (max-width: 490px) {
    .bank-account-create-modal .steps-bar {
        width: 75%;
    }
}

.loader-spinner-content {
    display: flex;
    justify-content: center;
    height: 200px;
    align-items: center;
    flex-direction: column;
}

.select-material.select-field {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #d3dbe7;
    border-radius: 0;
}

.opacity-visible {
    opacity: 1;
}

.opacity-hidden {
    opacity: 0;
}

.toast {
    z-index: 1051;
}

.user-select-none {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -o-user-select: none;
}

.dropdown-menu {
    z-index: 1030;
}

.modal-dialog .btn {
    display: initial !important;
}

/* start style for loader box */

.loader-box {
    height: 144px;
    width: 298px;
    background-color: #f3f5f7;
    overflow: hidden;
}

.loader-box:after {
    content: '';
    height: 100%;
    width: 100%;
    display: block;
    /* FF3.6-15 */
    background: -webkit-linear-gradient( left, rgba(230, 230, 230, 0) 0%, rgba(221, 221, 221, 0.2) 42%, rgba(221, 221, 221, 0.2) 62%, rgba(230, 230, 230, 0) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: -webkit-gradient( linear, left top, right top, from(rgba(230, 230, 230, 0)), color-stop(42%, rgba(221, 221, 221, 0.2)), color-stop(62%, rgba(221, 221, 221, 0.2)), to(rgba(230, 230, 230, 0)));
    background: -o-linear-gradient( left, rgba(230, 230, 230, 0) 0%, rgba(221, 221, 221, 0.2) 42%, rgba(221, 221, 221, 0.2) 62%, rgba(230, 230, 230, 0) 100%);
    background: linear-gradient( to right, rgba(230, 230, 230, 0) 0%, rgba(221, 221, 221, 0.2) 42%, rgba(221, 221, 221, 0.2) 62%, rgba(230, 230, 230, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00e6e6e6', endColorstr='#00e6e6e6', GradientType=1);
    /* IE6-9 */
    -webkit-animation: load 1.2s ease-out infinite;
    animation: load 1.2s ease-out infinite;
}

@keyframes load {
    0% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
    100% {
        -webkit-transform: translateX(200%);
        transform: translateX(200%);
    }
}

/* end style for loader box */

.show-loader {
    opacity: 1;
    transition: opacity 0.1s ease-in-out;
}

.hide-loader {
    opacity: 0;
    transition: opacity 2s ease-in-out;
}

.load-screen .load-mockup {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    pointer-events: none;
    z-index: 10;
}

.load-screen .visible {
    opacity: 1;
}

.load-screen .invisible {
    opacity: 0;
}

.associations-logo {
    font-size: 15px;
    font-weight: 600;
    border-left: 1px solid rgba(255, 255, 255, 0.41);
    margin-left: 6px;
    padding-left: 6px;
}

.App-brand:hover .associations-logo,
.App-brand:focus .associations-logo {
    color: rgba(255, 255, 255, 0.59);
}

.status-dashed {
    border-bottom: 1px dashed #979797;
    padding-bottom: 3px;
    margin-top: 2px;
}

@media (min-width: 768px) and (max-width: 991px) {
    .associations-logo {
        display: block;
        padding: 0;
        border: 0;
        margin: 0 0 0 35px;
        text-align: center;
        position: absolute;
        font-size: 12px;
    }
}

.avatar-user-container .user-avatar {
    display: inline-block;
    vertical-align: middle;
}

.avatar-user-container .user-name {
    vertical-align: middle;
}

.item-detail .detail-header {
    display: flex;
    align-items: center;
    margin: 15px 0px 18px;
}

.item-detail .detail-header h2 {
    margin-top: 5px;
    margin-bottom: 4px;
    font-size: 24px;
    line-height: 33px;
}

.item-detail .detail-header .header-subtitle {
    color: #FFFFFF;
    font-size: 14px;
    line-height: 19px;
}

.item-detail .detail-nav-container {
    position: relative;
}

.item-detail .detail-nav-container .actions-button {
    position: absolute;
    top: 45px;
    right: 0;
}

@media (max-width: 345px) {
    .item-detail .detail-nav-container .actions-button {
        position: unset;
        margin-bottom: 15px;
    }
}

.shine {
    background: #f6f7f8;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%) !important;
    background-repeat: no-repeat;
    background-size: 800px 104px !important;
    display: inline-block !important;
    position: relative;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
}

box {
    height: 104px;
    width: 100px;
}

lines {
    height: 10px;
    width: 100%;
}

photo {
    display: block !important;
    width: 325px;
    height: 100px;
}

@keyframes placeholderShimmer {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}

.justify-content-around {
    display: flex;
    justify-content: space-around;
}

.position-absolute {
    position: absolute;
}

.tr-progress-bar {
    border: 0px !important;
    background: transparent !important;
    all: unset !important;
    padding: 0px !important;
}

.tr-progress-bar td {
    all: unset !important;
    padding: 0px !important;
}

.tr-progress-bar td div {
    display: initial !important;
}

.progressbar {
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
    margin-bottom: 0px; 
}

.show-progress {
    opacity: 1;
    transition: opacity 0.4s ease-in-out;
}
.isLoadingTable {
    background: #FFF;
    border-bottom: 1px solid #e6e8ec;
}

.isLoadingTable tbody tr:nth-child(1n+1) {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
}