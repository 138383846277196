.app-footer- {
    padding: 60px 40px 40px 40px;
    text-align: center;
    font-size: 13px;
}

.app-footer- h3 {
    color: #4C5656;
    font-family: "Open Sans";
    font-size: 14px;
}

.app-footer- a {
    color: #42A5F5;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
}