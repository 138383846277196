.navbar-aside {
    display: none;
}

.navbar-header .menu-icon {
    display: none;
}

.active > a{
    color: white !important;
}
